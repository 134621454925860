var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-table",
                  {
                    ref: "table",
                    attrs: {
                      title: "업무일지 결과 목록",
                      columns: _vm.grid.columns,
                      data: _vm.safetyCheck.safetyCheckResultModelList,
                      gridHeight: _vm.grid.height,
                      rowKey: "safetyCheckResultId",
                      editable: !_vm.disabled,
                      columnSetting: false,
                      filtering: false,
                      usePaging: false,
                    },
                    on: { headLinkClick: _vm.headLinkClick },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            props.row[col.key] === "MSR0000001"
                              ? [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "radio_button_unchecked",
                                      color: _vm.customColor(col, props.row),
                                    },
                                  }),
                                ]
                              : props.row[col.key] === "MSR0000002"
                              ? [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "close",
                                      color: _vm.customColor(col, props.row),
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "prefixContent" },
                      [
                        !_vm.safetyCheck.plantCd || !_vm.safetyCheck.checkDate
                          ? _c("font", { attrs: { color: "#C10015" } }, [
                              _vm._v(
                                " " + _vm._s(_vm.$label("LBL0002213")) + " "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c(
            "c-dialog",
            {
              attrs: {
                param: _vm.popupOptions,
                returnData: { col1: _vm.safetyCheck.safetyCheckId },
              },
            },
            [_vm._v(">")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }